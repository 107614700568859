<template>
  <div>
    <v-app-bar
      app
      color="sky-grey darken-1"
      height="90"
      class="px-6"
      width="100vw"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <img src="/assets/L2Sky-logo-white.png" contain height="70" width="161" />
      <v-spacer></v-spacer>
      <v-list class="pa-0" color="transparent">
        <v-list-item-group
          v-model="selected"
          class="d-flex"
          mandatory
          color="transparent"
        >
          <template v-for="(item, i) in itemsNavBar">
            <v-hover v-slot="{ hover }" :key="i">
              <v-list-item
                :href="item.path"
                link
                class="white--text header-item mx-4"
                active-class="active-item sky-blue--text text--lighten-1"
                :class="{ 'transition-color': hover }"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ item.name }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
          </template>
        </v-list-item-group>
      </v-list>
    </v-app-bar>

    <template v-else>
      <v-app-bar app color="sky-grey darken-1" height="90" class="px-6" dark>
        <v-app-bar-nav-icon @click="activeDrawer = true">
          <v-icon size="35"> mdi-menu </v-icon>
        </v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <img
          src="/assets/L2Sky-logo-white.png"
          contain
          height="50"
          width="131"
        />
      </v-app-bar>
      <v-navigation-drawer v-model="activeDrawer" app temporary>
        <v-list nav dense class="mt-10">
          <v-list-item-group
            v-model="group"
            v-for="(item, i) in itemsNavBar"
            :key="i"
            no-action
          >
            <v-list-item :href="item.path" link class="pl-6 mb-3">
              <v-list-item-icon>
                <v-icon color="sky-blue">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title
                class="text-uppercase sky-blue--text text--darken-1"
              >
                <span>{{ item.name }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </template>
    <slot />
    <base-social-drawer v-if="$vuetify.breakpoint.mdAndUp" />
  </div>
</template>

<script>
import BaseSocialDrawer from "@/components/BaseSocialDrawer.vue";

export default {
  name: "Default",
  components: {
    BaseSocialDrawer,
  },
  data() {
    return {
      itemsNavBar: [
        {
          name: "Accueil",
          path: "#accueil",
          active: true,
          icon: "mdi-home",
        },
        {
          name: "Services",
          path: "#services",
          icon: "mdi-drone",
        },
        {
          name: "Pourquoi nous?",
          path: "#pourquoi-nous",
          icon: "mdi-diamond-stone",
        },
        {
          name: "Equipe",
          path: "#equipe",
          icon: "mdi-account-group",
        },
        {
          name: "Contact",
          path: "#contact",
          icon: "mdi-email-send",
        },
      ],
      selected: null,
      activeDrawer: false,
      group: null,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.layout-default {
  padding-top: 131px;
  box-sizing: border-box;
}
.header-item {
  font-size: 16px;
  font-weight: 600;
}
.transition-color {
  transition: color 0.2s linear;
}
.transition-color:hover {
  color: map-deep-get($colors, "sky-blue", "lighten-1") !important;
}
.active-item {
  border-bottom: 5px solid map-deep-get($colors, "sky-blue", "lighten-1") !important;
  border-radius: 0px;
}

.v-list-item--link:before {
  background-color: transparent !important;
}
</style>