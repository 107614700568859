<template>
  <div class="drawer-tabs">
    <template v-for="(item) in items">
      <div 
        class="drawer-tabs-item d-flex align-center justify-center"
        :key="item.title"
      >
        <a :href="item.links" target="_blank">
          <v-icon
            size="30"
            color="white"
          >
            {{item.icon}}
          </v-icon>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "BaseSocialDrawer",
  data () {
    return {
      items: [
        {
          title: 'YouTube',
          icon: 'mdi-youtube',
          links: 'https://www.youtube.com/channel/UCKtTpDFlD49oBhMOmhqNi1w'
        },
        { 
          title: 'Instagram', 
          icon: 'mdi-instagram',
          links: 'https://www.instagram.com/l2sky.drone/'
        },
        { 
          title: 'Facebook', 
          icon: 'mdi-facebook',
          links: 'https://www.facebook.com/L2Sky-Prenez-de-la-hauteur-106052968505026/'
        },

      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
a {
  text-decoration: none;
}
.drawer-tabs{
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  position: fixed;
  right: 0;
}
.drawer-tabs-item{
  background-color: map-deep-get($colors, 'sky-blue', 'darken-1') ;
  margin-top: 3px;
  width: 60px;
  height: 60px;
  transition: width 0.3s linear;
  -webkit-transition: width 0.3s linear;
  margin-left: auto;
}
.drawer-tabs-item:hover {
  width: 90px;
}
</style>